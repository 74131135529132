import { createMuiTheme, makeStyles } from "@material-ui/core";

const defaultTheme = createMuiTheme();
const useStyles = makeStyles({
  "@global": {
    body: {
      //      background: "#F0F0F0 url(/angle-bg@2x.jpg) no-repeat top left",
      //      backgroundSize: "126%",
      backgroundColor: "white",
      width: "100%",
      fontFamily:
        "'Cabin',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
    }
  },
  root: {},
  h1: {
    color: "white",
    fontSize: "48px",
    margin: "0",
    padding: "0",
    [defaultTheme.breakpoints.down(840)]: {
      fontSize: "36px"
    },
    [defaultTheme.breakpoints.down(720)]: {
      width: "100%",
      textAlign: "center"
    }
  },
  h2: {
    color: "#3F3F3F",
    fontSize: "30px",
    [defaultTheme.breakpoints.down(720)]: {
      fontSize: "24px",
      textAlign: "center"
    },
    [defaultTheme.breakpoints.down(540)]: {
      background: "#f7f7f7"
    }
  },
  stepNum: {
    color: "#2DD1E5",
    fontWeight: "bold",
    letterSpacing: ".05em",
    textTransform: "uppercase",
    fontSize: "16px",
    [defaultTheme.breakpoints.down(720)]: {
      fontSize: "13px",
      display: "block",
      textAlign: "center",
      marginTop: "12px",
      marginBottom: "4px"
    },
    [defaultTheme.breakpoints.down(540)]: {
      background: "#f7f7f7"
    }
  },
  subHeader: {
    verticalAlign: "top",
    display: "flex",
    alignContent: "space-between",
    justifyContent: "space-between",
    paddingTop: "72px",
    paddingBottom: "86px",
    [defaultTheme.breakpoints.down(720)]: {
      textAlign: "center",
      display: "block",
      paddingBottom: "68px",
      paddingTop: "0"
    }
  },
  subHeaderLeft: {
    position: "relative",
    paddingBottom: "56px",
    "&::after": {
      content: '""',
      height: "5px",
      width: "240px",
      background: "linear-gradient(to right, #00c9fb 0%, #79cc81 100%)",
      position: "absolute",
      bottom: "0",
      left: "0",
      [defaultTheme.breakpoints.down(720)]: {
        width: "120px",
        left: "calc(50% - 60px)"
      }
    },

    [defaultTheme.breakpoints.down(720)]: {
      width: "100%",
      display: "flex",
      textAlign: "center",
      flexDirection: "column-reverse",
      "&::after": {
        left: "calc(50% - 120px)",
        height: "2px"
      }
    }
  },
  buttonWrap: {
    width: "33.33333%",
    [defaultTheme.breakpoints.down(1024)]: {
      width: "50%"
    },
    [defaultTheme.breakpoints.down(540)]: {
      width: "100%"
    }
  },
  stepWrap: {
    [defaultTheme.breakpoints.down(540)]: {
      position: "relative"
      // marginLeft: "-48px",
      // marginTop: "42px"
    }
  },
  sectionPadding: {
    background: "#f7f7f7",
    borderRadius: "6px",
    minHeight: "1000px",
    marginBottom: "60px",
    position: "relative",
    padding: "72px 72px 0 72px",
    [defaultTheme.breakpoints.down(1024)]: {
      padding: "48px 48px 0 48px"
    },
    [defaultTheme.breakpoints.down(720)]: {
      padding: "36px 36px 0 36px"
    },
    [defaultTheme.breakpoints.down(540)]: {
      padding: "24px 24px 0 24px"
    },
    " p": {
      fontSize: "13px",
      [defaultTheme.breakpoints.down(540)]: {
        background: "#f7f7f7"
      }
    }
  },
  blockOne: {
    marginRight: "48px",
    position: "relative",
    zindex: "1",
    [defaultTheme.breakpoints.down(720)]: {
      marginRight: "0",
      position: "absolute",
      top: "-54px",
      left: "calc(50% - 24px)"
    },
    "& > img": {
      [defaultTheme.breakpoints.down(1180)]: {
        width: "64px",
        height: "auto",
        display: "block"
      },
      [defaultTheme.breakpoints.down(720)]: {
        width: "48px",
        height: "auto",
        display: "block"
      }
    }
  },
  blockTwo: {
    marginRight: "48px",
    position: "relative",
    zindex: "1",
    [defaultTheme.breakpoints.down(720)]: {
      marginRight: "0",
      position: "absolute",
      top: "-64px",
      left: "calc(50% - 24px)"
    },
    "& > img": {
      [defaultTheme.breakpoints.down(1180)]: {
        width: "64px",
        height: "auto",
        display: "block"
      },
      [defaultTheme.breakpoints.down(720)]: {
        width: "48px",
        height: "auto",
        display: "block"
      }
    }
  },
  blockThree: {
    marginRight: "48px",
    position: "relative",
    zindex: "1",
    [defaultTheme.breakpoints.down(720)]: {
      marginRight: "0",
      position: "absolute",
      top: "-64px",
      left: "calc(50% - 24px)"
    },
    "& > img": {
      [defaultTheme.breakpoints.down(1180)]: {
        width: "64px",
        height: "auto",
        display: "block"
      },
      [defaultTheme.breakpoints.down(720)]: {
        width: "48px",
        height: "auto",
        display: "block"
      }
    }
  },
  paragraphs: {
    margin: "0 0 24px 0",
    padding: "16px 0",
    fontSize: "16px",
    color: "#757575",
    lineHeight: "1.5",
    [defaultTheme.breakpoints.down(720)]: {
      fontSize: "13px",
      textAlign: "center"
    },
    [defaultTheme.breakpoints.down(540)]: {
      background: "#f7f7f7"
    }
  },
  alabel: {
    "& span.Mui-checked + span.MuiTypography-body1": {
      color: "#3F3F3F",
      fontWeight: "500"
    },
    "& span.Mui-checked + span.MuiTypography-body1 > div > p": {
      color: "#3F3F3F",
      fontWeight: "500"
    },
    "& div.MuiFormGroup-root + span.MuiTypography-body1": {
      color: "#3F3F3F",
      fontWeight: "500",
      display: "none"
    }
  },
  dlabel: {
    "& div.MuiInput-underline + span.MuiTypography-body1": {
      color: "#3F3F3F",
      fontWeight: "500"
    }
  },
  maxWidthWide: {
    maxWidth: "1280px",
    paddingLeft: "48px",
    paddingRight: "48px",
    [defaultTheme.breakpoints.down(720)]: {
      paddingLeft: "12px",
      paddingRight: "12px"
    }
  },
  maxWidth: {
    maxWidth: "1168px",
    paddingLeft: "48px",
    paddingRight: "48px",
    [defaultTheme.breakpoints.down(720)]: {
      paddingLeft: "12px",
      paddingRight: "12px"
    }
  },
  fieldError: {
    color: "rgb(97, 26, 21)",
    marginTop: 4,
    backgroundColor: "rgb(253, 236, 234)",
    borderRadius: 4,
    display: "flex",
    padding: 4,
    alignItems: "center"
  },
  heroText: {
    color: "#42DCB8",
    fontSize: "16px",
    letterSpacing: ".075em",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: "0",
    [defaultTheme.breakpoints.down("sm")]: {
      fontSize: "13px",
      marginTop: "12px"
    }
  },
  heroParagraphText: {
    fontWeight: "normal",
    fontSize: "16px",
    color: "#888888",
    marginTop: "8px",
    position: "relative"
  },
  sectionOne: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: "54px",
      top: "150px",
      bottom: "0",
      width: "2px",
      height: "calc(100% - 110px)",
      backgroundColor: "#31D4DC",
      [defaultTheme.breakpoints.down(1180)]: {
        top: "96px",
        left: "32px",
        width: "1px"
      },
      [defaultTheme.breakpoints.down(720)]: {
        top: "36px",
        left: "11px",
        width: "1px",
        display: "none"
      }
    }
  },
  sectionTwo: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: "54px",
      top: "150px",
      bottom: "-100px",
      width: "2px",
      height: "calc(100% - 110px)",
      backgroundColor: "#46DEB0",
      [defaultTheme.breakpoints.down(1180)]: {
        top: "96px",
        left: "32px",
        width: "1px"
      },
      [defaultTheme.breakpoints.down(720)]: {
        top: "36px",
        left: "11px",
        width: "1px",
        display: "none"
      }
    }
  },
  sectionThree: {
    marginBottom: "72px",
    position: "relative",
    display: "flex",
    [defaultTheme.breakpoints.down(720)]: {
      marginBottom: "24px"
    },
    "&::before": {
      content: '""',
      position: "absolute",
      left: "54px",
      top: "150px",
      bottom: "-100px",
      width: "2px",
      height: "calc(100% - 110px)",
      backgroundColor: "#58E886",
      [defaultTheme.breakpoints.down(1180)]: {
        top: "96px",
        left: "32px",
        width: "1px"
      },
      [defaultTheme.breakpoints.down(720)]: {
        top: "36px",
        left: "11px",
        width: "1px",
        display: "none"
      }
    }
  },
  bottomBar: {
    width: "100%",
    backgroundColor: "#fff",
    position: "fixed",
    bottom: 0,
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    maxWidth: "none",
    margin: "0 auto",
    "&::before": {
      content: '""',
      position: "absolute",
      height: "1px",
      width: "100%",
      display: "block",
      // background: "#00c9fb",
      // background: "-moz-linear-gradient(left,  #00c9fb 0%, #79CC81 100%)",
      // background: "-webkit-linear-gradient(left,  #00c9fb 0%,#79CC81 100%)",
      background: "linear-gradient(to right,  #00c9fb 0%,#79CC81 100%)",
      left: 0,
      top: 0,
      right: 0
    }
  },
  heroWrap: {
    width: "auto",
    [defaultTheme.breakpoints.down(720)]: {
      marginBottom: 0,
      paddingTop: "0px",
      paddingBottom: "0px"
    }
  },
  textBoxField: {
    fontFamily: "'Cabin', sans-serif",
    [defaultTheme.breakpoints.down(720)]: {
      width: "calc(100% - 36px)"
    }
  },
  bottomRow: {
    borderTop: "1px solid #E6E6E6",
    padding: "54px",
    textAlign: "center",
    width: "auto",
    marginLeft: "-72px",
    marginRight: "-72px",
    [defaultTheme.breakpoints.down(1024)]: {
      marginLeft: "-48px",
      marginRight: "-48px"
    },
    [defaultTheme.breakpoints.down(720)]: {
      marginLeft: "-36px",
      marginRight: "-36px"
    }
  },
  beta: {}
});

export default useStyles;
