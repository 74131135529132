import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { PropsWithChildren } from "react";
import { createMuiTheme } from "@material-ui/core/styles";

interface props {
  title?: string;
  subtitle?: string;
  selectionText?: (() => string) | string;
  active?: boolean;
}
const defaultTheme = createMuiTheme();
const useStyles = makeStyles({
  root: {
    padding: "38px",
    [defaultTheme.breakpoints.down(400)]: {
      padding: "20px"
    }
  },
  before: {
    overflow: "visible",
    position: "relative",
    padding: "8px 0",
    "&::before": {
      content: '""',
      position: "absolute",
      height: "48px",
      width: 4,
      display: "block",
      background: grey[600],
      left: -38,
      top: 0,
      [defaultTheme.breakpoints.down(400)]: {
        width: 2,
        left: -20
      }
    },
    "&.active::before": {
      background: "#79CC81"
    }
  },
  labels: {
    fontSize: 18,
    paddingTop: 10,
    color: grey[600],
    "&.active": {
      color: "#79CC81",
      "& h6": {
        background: "#F0F8F1"
      }
    }
  },
  currentConfig: {
    background: "transparent",
    borderRadius: "6px",
    fontSize: "14px",
    padding: "4px 0",
    textAlign: "center",
    textTransform: "uppercase",
    width: "150px"
  }
});

export default function BoxWrapper(props: PropsWithChildren<props>) {
  const classes = useStyles(props);
  const selectionText =
    typeof props.selectionText === "function"
      ? props.selectionText()
      : props.selectionText;
  return (
    <Grid item xs={12} style={{}}>
      <Box
        className={classes.root}
        padding="{0}"
        style={{
          borderRadius: "6px",
          marginBottom: "24px",
          verticalAlign: "top",
          backgroundColor: "#fff",
          boxShadow:
            "rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px"
        }}
      >
        <Grid container style={{}}>
          <Grid
            item
            xs={12}
            sm={8}
            className={`${classes.before} ${props.active ? "active" : ""}`}
          >
            <Typography variant="h2">{props.title}</Typography>
            <Typography
              variant="subtitle1"
              dangerouslySetInnerHTML={{ __html: props.subtitle as string }}
            ></Typography>
          </Grid>
          <Grid
            item
            className={`${classes.labels} ${props.active ? "active" : ""}`}
            sm={4}
            style={{ textAlign: "right" }}
          >
            <Box
              display={{ xs: "none", sm: "flex" }}
              style={{ justifyContent: "flex-end" }}
            >
              <Typography
                variant="h6"
                className={`
                  ${classes.currentConfig} ${
                  selectionText === "COMING SOON" ? "coming-soon" : ""
                }
                `}
              >
                {selectionText}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {props.children}
      </Box>
    </Grid>
  );
}
