import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  Snackbar,
} from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import Refresh from '@material-ui/icons/Refresh'
import DownloadIcon from '@material-ui/icons/GetApp'
import { useSelector } from 'react-redux'
import { RootState } from '../features'
import Header from './shared/Header'
import Footer from './shared/Footer'
import Confetti from 'react-dom-confetti'
import { getBaseUrl } from '../config/config'

const config = {
  angle: 110,
  spread: 250,
  startVelocity: 45,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: '10px' as any,
  height: '10px' as any,
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
}

const defaultTheme = createMuiTheme()
const useStyles = makeStyles({
  '@global': {
    body: {
      backgroundColor: 'white',
      width: '100%',
      fontFamily:
        "'Cabin',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    },
    [defaultTheme.breakpoints.up(1200)]: {
      'body::before': {
        minHeight: '1200px',
      },
    },
  },

  root: {},
  alabel: {
    '& span.Mui-checked + span.MuiTypography-body1': {
      color: '#3F3F3F',
      fontWeight: '500',
    },
    '& span.Mui-checked + span.MuiTypography-body1 > div > p': {
      color: '#3F3F3F',
      fontWeight: '500',
    },
    '& div.MuiFormGroup-root + span.MuiTypography-body1': {
      color: '#3F3F3F',
      fontWeight: '500',
      display: 'none',
    },
  },
  dlabel: {
    '& div.MuiInput-underline + span.MuiTypography-body1': {
      color: '#3F3F3F',
      fontWeight: '500',
    },
  },
  maxWidthWide: {
    maxWidth: '1376px',
    paddingLeft: '48px',
    paddingRight: '48px',
    [defaultTheme.breakpoints.down(720)]: {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
  maxWidth: {
    maxWidth: '1168px',
    paddingLeft: '48px',
    paddingRight: '48px',
    [defaultTheme.breakpoints.down(720)]: {
      paddingLeft: '12px',
      paddingRight: '12px',
    },
  },
  bottomBar: {
    width: '100%',
    backgroundColor: '#fff',
    position: 'fixed',
    bottom: 0,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    maxWidth: 'none',
    margin: '0 auto',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: '1px',
      width: '100%',
      display: 'block',
      // background: "#00c9fb",
      // background: "-moz-linear-gradient(left,  #00c9fb 0%, #79CC81 100%)",
      // background: "-webkit-linear-gradient(left,  #00c9fb 0%,#79CC81 100%)",
      background: 'linear-gradient(to right,  #00c9fb 0%,#79CC81 100%)',
      left: 0,
      top: 0,
      right: 0,
    },
  },
  heroWrap: {
    width: 'auto',
    [defaultTheme.breakpoints.down(720)]: {
      marginBottom: 0,
      paddingTop: '0px',
      paddingBottom: '0px',
    },
  },
  subHeader: {
    verticalAlign: 'top',
    display: 'flex',
    alignContent: 'space-between',
    justifyContent: 'space-between',
    paddingTop: '72px',
    paddingBottom: '86px',
    [defaultTheme.breakpoints.down(720)]: {
      textAlign: 'center',
      display: 'block',
      paddingBottom: '68px',
      paddingTop: '0',
    },
  },
  subHeaderLeft: {
    position: 'relative',
    paddingBottom: '56px',
    '&::after': {
      content: '""',
      height: '5px',
      width: '240px',
      background: 'linear-gradient(to right, #00c9fb 0%, #79cc81 100%)',
      position: 'absolute',
      bottom: '0',
      left: '0',
    },

    [defaultTheme.breakpoints.down(720)]: {
      width: '100%',
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column-reverse',
      '&::after': {
        left: 'calc(50% - 120px)',
        height: '2px',
      },
    },
  },
  heroText: {
    color: '#42DCB8',
    fontSize: '16px',
    letterSpacing: '.075em',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: '0',
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: '13px',
      marginTop: '12px',
    },
  },
  h1: {
    color: 'white',
    fontSize: '48px',
    margin: '0',
    padding: '0',
    [defaultTheme.breakpoints.down(840)]: {
      fontSize: '36px',
    },
    [defaultTheme.breakpoints.down(720)]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  h2: {
    color: '#3F3F3F',
    fontSize: '30px',
    fontWeight: 'normal',
    [defaultTheme.breakpoints.down(720)]: {
      fontSize: '24px',
      textAlign: 'center',
    },
    [defaultTheme.breakpoints.down(540)]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  stepNum: {
    color: '#2DD1E5',
    fontWeight: 'bold',
    letterSpacing: '.05em',
    textTransform: 'uppercase',
    fontSize: '16px',
    [defaultTheme.breakpoints.down(720)]: {
      fontSize: '13px',
      display: 'block',
      textAlign: 'center',
      marginTop: '12px',
      marginBottom: '4px',
    },
    [defaultTheme.breakpoints.down(540)]: {},
  },
  downloadRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1',
    width: '100%',
    [defaultTheme.breakpoints.down(960)]: {
      display: 'block',
    },
  },
  githubBox: {
    display: 'flex',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    flex: '1',
    alignItems: 'center',
    background: 'white',
    border: '3px solid #79CC81',
    width: 'calc(100% - 275px)',
    marginRight: '16px',
    marginTop: '0',
    marginBottom: '0',
    padding: '4px',
    borderRadius: '6px',
    [defaultTheme.breakpoints.down(960)]: {
      width: '100%',
    },
  },
  downloadButton: {
    marginTop: '4px',
    marginBottom: '4px',
    height: 'auto',
    background: 'none',
    width: '256px',
    padding: '.35em .7em',
    border: '1px solid #79CC81',
    color: '#79CC81',
    fontWeight: 'bold',
    [defaultTheme.breakpoints.down(960)]: {
      marginTop: '12px',
      width: '200px',
    },
    [defaultTheme.breakpoints.down(540)]: {
      marginTop: '12px',
      width: '100%',
    },
  },
  sectionPadding: {
    background: '#f7f7f7',
    borderRadius: '6px',
    position: 'relative',
    padding: '72px 72px 0 72px',
    [defaultTheme.breakpoints.down(1024)]: {
      padding: '48px 48px 0 48px',
    },
    [defaultTheme.breakpoints.down(720)]: {
      padding: '36px 36px 0 36px',
    },
    '& p': {
      fontSize: '13px',
    },
  },
  bottomRow: {
    borderTop: '1px solid #e6e6e6',
    padding: '54px',
    marginTop: '36px',
    alignItems: 'center',
    alignContent: 'center',
    [defaultTheme.breakpoints.down(720)]: {
      padding: '36px',
    },
  },
  blockchainImageWrap: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    [defaultTheme.breakpoints.down(720)]: {
      flexDirection: 'column',
    },
  },
  blockchainImage: {
    display: 'flex',
    alignItems: 'center',
    height: '80px',
    width: '80px',
    marginRight: '16px',
    border: '3px solid #e6e6e6',
    borderRadius: '100px',
    padding: '12px',
    '& > img': {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
      height: 'auto',
      margin: '0 auto',
    },
    [defaultTheme.breakpoints.down(720)]: {
      margin: '0 auto',
    },
  },
  addBox: {
    background: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: '6px',
    marginBottom: '60px',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '236px',
    padding: '24px 540px 24px 48px',
    display: 'flex',
    alignItems: 'center',
    [defaultTheme.breakpoints.down(960)]: {
      flexDirection: 'column',
      textAlign: 'center',
      alignContent: 'center',
      padding: '24px 24px 0px 24px',
    },
  },
  cliImg: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    borderTop: '3px solid rgb(255, 217, 2)',
    borderLeft: '3px solid rgb(255, 217, 2)',
    [defaultTheme.breakpoints.down(960)]: {
      position: 'static',
      display: 'block',
      borderRight: '3px solid rgb(255, 217, 2)',
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      margin: '36px auto 0',
    },
  },
  newsletterImg: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    [defaultTheme.breakpoints.down(960)]: {
      position: 'static',
      display: 'block',
      borderRight: '3px solid rgb(255, 217, 2)',
      width: '100%',
      maxWidth: '400px',
      height: 'auto',
      margin: '36px auto 0',
    },
  },
  mainBox: {
    background: '#f7f7f7',
    borderRadius: '6px',
    marginBottom: '24px',
    position: 'relative',
    padding: '0',
    [defaultTheme.breakpoints.down(960)]: {
      marginBottom: '12px',
    },
  },
  confettiWrap: {
    position: 'absolute',
    zIndex: 3,
    top: '80px',
    left: '80px',
    [defaultTheme.breakpoints.down(720)]: {
      top: '30px',
      left: '30px',
    },
  },
})

export default function DappDone() {
  const classes = useStyles()
  const history = useHistory()
  const projectName = useSelector((state: RootState) => state.dapp.name)
  const selections = useSelector((state: RootState) => state.dapp.selections)
  const [message, setMessage] = useState<String>('')
  const downloadUrl = getBaseUrl() + '/download/'+ projectName;
  const downloadNow = () => {
    let lnk = document.createElement('a')
    lnk.href = downloadUrl
    lnk.click()
  }

  const [isPartying, setPartying] = useState(false)
  useEffect(() => {
    setTimeout(function () {
      setPartying(true)
      setPartying(false)
    }, 500)
  }, [])

  function setThePartying() {
    setPartying(true)
    setTimeout(function () {
      setPartying(false)
    }, 500)
  }

  return (
    <>
      <Snackbar
        open={message !== ''}
        onClose={() => setMessage('')}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={<div>{message}</div>}
      ></Snackbar>
      <Header />

      <div className="block-wrap">
        <div className="blocks done">
          <img
            className="done"
            src="/blocks-svg-static.svg"
            height="auto"
            width="1669"
            alt="block"
          />
        </div>
      </div>
      <Container className={classes.maxWidth} style={{ position: 'relative' }}>
        <div className={classes.heroWrap}>
          <Grid style={{ width: '100%' }}>
            <Box className={classes.subHeader}>
              <div className={classes.subHeaderLeft}>
                <span className={classes.heroText}>
                  Thanks for using DappStarter
                </span>
                <Typography variant="h1" className={classes.h1}>
                  You're All Set!
                </Typography>
              </div>
            </Box>
          </Grid>
          {/*
          <Grid item xs={6}>
            <img alt="" src="/dapp-blocks-animation.svg" />
          </Grid>
          */}
        </div>
      </Container>

      <Container className={classes.maxWidth}>
        <div className="checkmark" id="checkmark">
          <img
            src="/checkmark.svg"
            height="223.582"
            width="196.201"
            alt="checkmark"
            onClick={() => setThePartying()}
          />
          <object
            type="image/svg+xml"
            height="115"
            width="118"
            data="/checkmark-squared.svg"
            className="checkmark-squared"
            style={{ pointerEvents: 'none' }}
          >
            <img
              src="/checkmark-squared.svg"
              height="115"
              width="118"
              alt="checkmark"
            />
          </object>
          <div className={classes.confettiWrap}>
            <Confetti active={isPartying} config={config} />
          </div>
        </div>
        <Box
          className={(classes.root, classes.mainBox)}
          boxShadow="0"
          padding="{0}"
        >
          <div className={classes.sectionPadding}>
            <Grid item xs={12} className={classes.blockchainImageWrap}>
              <div className={classes.blockchainImage}>
                {/*
                <img
                  alt=""
                  src="https://uploads-ssl.webflow.com/5dea4f8b31edea3328b9a0f6/5e26f883496ea0024c191ae2_quorum.png"
                  height="60"
                ></img>
                */}

                <img alt="" src="/block-3.svg" height="60"></img>

                {/*
                <img
                  alt=""
                  src="https://uploads-ssl.webflow.com/5dea4f8b31edea3328b9a0f6/5e26f8717082827713af2e15_harmony.png"
                  height="60"
                ></img>
                */}
              </div>
              <div>
                {selections.name !== '' ? (
                  <Typography variant="subtitle1" className={classes.stepNum}>
                    DappStarter Project
                  </Typography>
                ) : null}
                <Typography variant="h2" className={classes.h2}>
                  {selections.name}
                </Typography>
              </div>
            </Grid>

            <div className={classes.downloadRow}>
              <Button
                variant="text"
                color="secondary"
                onClick={downloadNow}
                className={classes.downloadButton}
              >
                <DownloadIcon style={{ marginRight: '8px' }} /> Download ZIP
              </Button>
              <p>
                <a href="https://github.com/decentology/vulcan-preview" target="_new" style={{fontSize: 20}}>Vulcan Preview Repo</a>
              </p>
            </div>

            <p
              style={{
                margin: '24px 0',
                padding: '16px 0',
                fontSize: '16px',
                color: '#757575',
                lineHeight: '1.5',
              }}
            >
              Blockchains are the underlying technology for the decentralized
              web. If you aren’t familiar with the decentralized web, it’s a
              good idea to get some familiarity with{' '}
              <a
                href="https://www.simplilearn.com/tutorials/blockchain-tutorial/how-to-become-a-blockchain-developer"
                target="_new"
              >
                how it works before you
              </a>{' '}
              start.
            </p>
          </div>

          {/*          <Button onClick={() => setPartying(true)}>Boop</Button> */}
          {/* When has the partying stopped? */}

          <Grid container className={classes.bottomRow}>
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/')}
                  style={{ textAlign: 'center' }}
                >
                  <Refresh
                    style={{ marginRight: '8px', opacity: '.75' }}
                  ></Refresh>
                  Create Another
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  )
}
