import React from 'react'
import { AppBar, Toolbar, Grid, makeStyles } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'


const defaultTheme = createMuiTheme()
const useStyles = makeStyles(() => ({
  logo: {
    width: '210px',
    [defaultTheme.breakpoints.down(720)]: {
      width: '100px',
    },
    [defaultTheme.breakpoints.down(500)]: {
      marginBottom: '24px',
    },
  },
  sectionMobile: {
    display: 'flex',
    alignContent: 'flex-end',
  },
  topBar: {
    display: 'flex',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1376px',
    paddingLeft: '48px',
    paddingRight: '48px',
    justifyContent: 'space-between',
    paddingTop: '24px',
    paddingBottom: '24px',
    [defaultTheme.breakpoints.down(720)]: {
      display: 'block',
      textAlign: 'center',
      paddingTop: '24px',
      paddingBottom: '0',
    },
  },
  mainNav: {
    fontSize: '14px',
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: '13px',
      width: '100%',
    },
    [defaultTheme.breakpoints.down(500)]: {
      display: 'none',
    },
    '& > ul': {
      display: 'flex',
      [defaultTheme.breakpoints.down(720)]: {
        justifyContent: 'center',
        paddingTop: '24px',
        paddingBottom: '24px',
        fontSize: '12px',
      },
    },
    '& li': {
      marginRight: '24px',
      '& a': {
        textTransform: 'uppercase',
        textDecoration: 'none',
        letterSpacing: '.075em',
        color: '#40DCBA',
        fontWeight: 'bold',
        transition: '.1s ease-in-out',
        '&:hover': {
          color: '#FFF',
          textDecoration: 'none',
        },
      },
      '&:first-child': {
        '& a': {
          color: '#2DD1E5',
        },
      },
      '&:last-child': {
        marginRight: '0',
        '& a': {
          color: '#5AE980',
          borderBottom: '3px solid rgba(255,255,255,.1)',
        },
      },
    },
  },
}))

export default function Header() {
  const classes = useStyles()
  return (
    <AppBar position="relative" style={{ paddingLeft: '0', paddingRight: '0' }}>
      <Toolbar className={classes.topBar}>
        {/* <SideMenu /> */}
        <div style={{}}>
          <a href="https://www.decentology.com/">
            <img
              className={classes.logo}
              src="https://info.decentology.com/assets/brand/SVG/decentology-logo-light.svg"
              alt="Dapp Starter"
              height="auto"
              width="210"
            />
          </a>
        </div>
        <div className={classes.sectionMobile}>
          <Grid container>
            <nav className={classes.mainNav}>
              <ul>
                {/*
                <li>
                  <Link
                    href="https://www.decentology.com/dappstarter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://discord.gg/decentology"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.decentology.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </Link>
                </li>
  */}
              </ul>
            </nav>
          </Grid>
        </div>
      </Toolbar>
    </AppBar>
  )
}
