import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../features";
import { getBaseUrl } from "../config/config";

interface DappSelections {
  name: string;
  blocks: {
    [key: string]: string | boolean;
  };
}

interface DappResult {
  name: string
  selections: DappSelections;
}

interface DappResultState extends DappResult {
  loading: boolean;
  error: string | null;
}

const dappState: DappResultState = {
  name: '',
  selections: {
    name: "Test",
    blocks: {}
  },
  loading: false,
  error: null
};

const dapp = createSlice({
  name: "dapp",
  initialState: dappState,
  reducers: {
    generateDapp(state, action: PayloadAction<DappSelections>) {
      state.loading = true;
      state.error = null;
      state.selections = action.payload;
    },
    generateDappSuccess(state, action: PayloadAction<DappResultState>) {
      state.loading = false;
      state.error = null;
      state.name = action.payload.name;
    },
    generateDappFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export default dapp.reducer;

export const generateDapp = (
  selections: any
): ThunkAction<
  Promise<any>,
  RootState,
  null,
  Action<string>
> => async dispatch => {

  dispatch(dapp.actions.generateDapp(selections));
  const url = getBaseUrl() + '/process';
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(selections)
  });
  if (response.ok) {
    const data = await response.json();
    dispatch(dapp.actions.generateDappSuccess(data));
    return data;
  }
  return dispatch(dapp.actions.generateDappFailure(response.statusText));
  
};
