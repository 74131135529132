import {
  Action,
  createAction,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../features/index";
import { find } from "lodash-es";
import { getBaseUrl } from "../config/config";

const testAction = createAction("test");

export interface ManifestState {
  loading: boolean;
  error: string | null;
  manifest: Manifest;
}

export interface ManifestInterface {
  hidden?: boolean;
  enabled?: boolean;
  children?: "single" | "multiple" | "none" | "single_required";
}

export interface ManifestElementBase {
  name: string;
  description: string;
  title: string;
  imageUrl?: string;
  help?: {
    title?: string;
    url?: string;
  };
  languages?: string[];
  blockchains?: string[];
  interface?: ManifestInterface;
  children?: ManifestElementBase[];
}

export interface Manifest {
  blockchain: ManifestElementBase;
  language: ManifestElementBase;
  categories: Category[];
  framework: ManifestElementBase;
}

export interface Category extends ManifestElementBase {
  blockchains: string[];
  languages: string[];
  dependencies?: {};
  children?: Category[];
  parameters?: CategoryParameter[];
}

export interface CategoryParameter {
  name: string;
  type: "string" | "number" | "checkmark" | "choice";
  title: string;
  placeholder?: string;
  required: boolean;
  default?: string;
  minLength?: number;
  maxLength?: number;
  description?: string;
  options: ManifestElementBase[];
}

const initialState: ManifestState = {
  manifest: {
    blockchain: {
      name: "",
      description: "",
      title: ""
    },
    language: {
      name: "",
      description: "",
      title: ""
    },
    categories: [],
    framework: {
      name: "",
      description: "",
      title: ""
    }
  },
  error: null,
  loading: false
};

const manifest = createSlice({
  name: "manifest",
  initialState,
  extraReducers: {
    [testAction.type]: state => { }
  },
  reducers: {
    getManifest(state) {
      state.loading = true;
      state.error = null;
    },
    getManifestSuccess(state, action: PayloadAction<ManifestElementBase[]>) {
      state.loading = false;
      state.error = null;
      state.manifest = {
        blockchain: find(
          action.payload,
          x => x.name === "blockchains"
        ) as ManifestElementBase,
        language: find(
          action.payload,
          x => x.name === "languages"
        ) as ManifestElementBase,
        categories: find(action.payload, x => x.name === "categories")
          ?.children as Category[],
        framework: find(
          action.payload,
          x => x.name === "frameworks"
        ) as ManifestElementBase
      };
    },
    getManifestFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export default manifest.reducer;

export const getManifest = (): ThunkAction<
  Promise<Manifest>,
  RootState,
  null,
  Action<string>
> => async dispatch => {
  try {
    const url = getBaseUrl() + '/manifest';
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      dispatch(manifest.actions.getManifestSuccess(data));
      return data;
    }
    return dispatch(manifest.actions.getManifestFailure(response.statusText));
  } catch (err) {
    return Promise.reject();
  }
};
