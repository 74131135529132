import { combineReducers, Action } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import { ThunkAction } from 'redux-thunk'
import manifestReducer from '../actions/manifest'
import dappReducer from '../actions/dapp'

const rootReducer = combineReducers({
    manifest: manifestReducer,
    dapp: dappReducer
});

const store = configureStore({ reducer: rootReducer });
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export default store;
