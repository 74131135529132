import {
  ManifestInterface,
  CategoryParameter,
  Category,
} from '../../actions/manifest'
import {
  FormControlLabel,
  FormGroup,
  Radio,
  makeStyles,
  RadioGroup,
  Checkbox,
  Typography,
  TextField,
} from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { findKey, omitBy } from 'lodash-es'
import BoxWrapper from './BoxWrapper'
import {
  FieldErrors,
  Controller,
  Control,
  FormStateProxy,
} from 'react-hook-form'

interface Props {
  categories: Category[]
  categorySelections: any
  blockchain: string
  language: string
  setCategorySelections: any
  register: any
  errors: FieldErrors<any>
  triggerValidation: any
  control: Control
  formState: FormStateProxy
}

const defaultTheme = createMuiTheme()
const useStyles = makeStyles({
  root: {},
  alabel: {
    '& span.Mui-checked + span.MuiTypography-body1': {
      color: '#3F3F3F',
      fontWeight: '500',
    },
    '& span.Mui-checked + span.MuiTypography-body1 > div > p': {
      color: '#3F3F3F',
      fontWeight: '500',
    },
    '& div.MuiFormGroup-root + span.MuiTypography-body1': {
      color: '#3F3F3F',
      fontWeight: '500',
      display: 'none',
    },
  },
  dlabel: {
    '& div.MuiInput-underline + span.MuiTypography-body1': {
      color: '#3F3F3F',
      fontWeight: '500',
    },
  },
  fieldError: {
    color: 'red',
    marginTop: 4,
    borderRadius: 4,
    padding: 4,
    alignItems: 'center',
  },
  maxWidth: {
    maxWidth: '720px',
  },
  indentFields: {
    paddingLeft: '36px',
    marginTop: '8px',
    marginBottom: '8px',
    paddingBottom: '36px',
    border: '1px solid #79CC81',
    borderRadius: '6px',
  },
  formControl: {
    fontFamily: "'Cabin', sans-serif",
    [defaultTheme.breakpoints.down(720)]: {
      width: 'calc(100% - 36px)',
    },
  },
})

const keyStartsWith = (obj: Object, str: string) => {
  for (const [key] of Object.entries(obj)) {
    if (key.indexOf(str) > -1) {
      return true
    }
  }
  return false
}

export default function DappFeature(props: Props) {
  const classes = useStyles()

  const [selectedText, setSelectedText] = useState<any>({})

  function configureValues(
    path: string,
    value: string | boolean | number | null,
    prune: boolean = false,
  ) {
    let temp = {
      ...categorySelections,
    }
    if (value !== '' && value !== null && value !== false) {
      if (prune) {
        temp = omitBy(temp, (value, key) => {
          return key.indexOf(path.substring(0, path.lastIndexOf('/'))) > -1
        })
      }
      temp[path] = value
    } else if (value === false && prune) {
      temp = omitBy(temp, (value, key) => {
        return key.indexOf(path.substring(0, path.lastIndexOf('/'))) > -1
      })
      delete temp[path]
    } else {
      delete temp[path]
    }
    setCategorySelections(temp)
  }
  const { setCategorySelections, categorySelections } = props

  function isActive(pathName: string) {
    return (
      findKey(categorySelections, (value, key) => {
        if (key.indexOf(pathName) > -1 && value) {
          return true
        }
        return false
      }) != null
    )
  }
  function showParameters(pathName: string, params: CategoryParameter[]) {
    return (
      <div className={classes.indentFields}>
        {params.map((p) => {
          if (p.type === 'string' || p.type === 'number') {
            return (
              <div key={p.name} style={{ marginTop: 12 }}>
                <Controller
                  control={props.control}
                  key={`${pathName}/${p.name}`}
                  name={`${pathName}/${p.name}`}
                  rules={{
                    required: p.required,
                    minLength: p.minLength,
                    maxLength: p.maxLength,
                  }}
                  label={p.title}
                  helperText={
                    props.errors[`${pathName}/${p.name}`] != null
                      ? `${p.title} is required`
                      : p.description
                  }
                  type={p.type === 'number' ? 'number' : 'text'}
                  error={props.errors[`${pathName}/${p.name}`] != null}
                  defaultValue={
                    categorySelections[`${pathName}/${p.name}`] || ''
                  }
                  onChange={([e]) => {
                    configureValues(`${pathName}/${p.name}`, e.target.value)
                    return e.target.value
                  }}
                  as={<TextField required={p.required} />}
                  className={classes.formControl}
                ></Controller>
              </div>
            )
          }
          if (p.type === 'choice') {
            return (
              <RadioGroup key={p.name}>
                {p.options.map((x) => {
                  return (
                    <FormControlLabel
                      key={x.name}
                      control={
                        <Radio
                          required
                          value={x.name}
                          checked={
                            categorySelections[`${pathName}/${p.name}`] ===
                            x.name
                          }
                          onChange={(e) => {
                            configureValues(
                              `${pathName}/${p.name}`,
                              e.target.value,
                            )
                          }}
                        />
                      }
                      label={x.title}
                    />
                  )
                })}
              </RadioGroup>
            )
          }
          return null
        })}
      </div>
    )
  }

  function renderChildren(
    pathName: string,
    ui?: ManifestInterface,
    children?: Category[],
  ) {
    let disabled = !ui?.enabled
    if (children != null) {
      if (ui?.children === 'multiple') {
        return children.map((c) => {
          if (
            !c.blockchains.includes(props.blockchain) ||
            !c.languages.includes(props.language)
          ) {
            return null
          }
          return (
            <div key={'/category_' + c.name}>
              <FormControlLabel
                key={c.name}
                // className={!c.interface?.enabled ? "coming-soon" : ""}
                // moved down to the p tag

                checked={categorySelections[`${pathName}/${c.name}`] || false}
                control={
                  <Checkbox
                    value={c.name}
                    disabled={disabled || !c.interface?.enabled}
                    onChange={(e) => {
                      configureValues(`${pathName}/${c.name}`, e.target.checked)
                    }}
                  />
                }
                label={
                  <div>
                    <Typography
                      variant="body1"
                      paragraph={false}
                      className={!c.interface?.enabled ? 'coming-soon' : ''}
                    >
                      {c.title}
                    </Typography>
                    <Typography variant="subtitle2">{c.description}</Typography>
                  </div>
                }
              />
              {c.parameters != null &&
              c.parameters.length > 0 &&
              categorySelections[`${pathName}/${c.name}`]
                ? showParameters(`${pathName}/${c.name}`, c.parameters)
                : null}
            </div>
          )
        })
      }
      if (ui?.children?.startsWith('single')) {
        if (children.length === 1) {
          return children.map((c) => {
            if (
              !c.blockchains.includes(props.blockchain) ||
              !c.languages.includes(props.language)
            ) {
              return null
            }
            return (
              <React.Fragment key={c.name}>
                <FormControlLabel
                  //className={!c.interface?.enabled ? "coming-soon" : ""}
                  // moved down to the p tag
                  checked={categorySelections[`${pathName}/${c.name}`] || false}
                  control={
                    <Checkbox
                      disabled={!c.interface?.enabled}
                      value={c.name}
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          setSelectedText({
                            ...selectedText,
                            [pathName]: c.title,
                          })
                        } else {
                          let { [pathName]: id, ...temp } = {
                            ...categorySelections,
                          }
                          setSelectedText(temp)
                        }

                        configureValues(
                          `${pathName}/${c.name}`,
                          e.target.checked,
                          true,
                        )
                      }}
                    />
                  }
                  label={
                    <div>
                      <Typography
                        variant="body1"
                        paragraph={false}
                        className={!c.interface?.enabled ? 'coming-soon' : ''}
                      >
                        {c.title}
                      </Typography>
                      <Typography variant="subtitle2">
                        {c.description}
                      </Typography>
                    </div>
                  }
                />
                <div>
                  {c.parameters != null &&
                  c.parameters.length > 0 &&
                  categorySelections[`${pathName}/${c.name}`]
                    ? showParameters(`${pathName}/${c.name}`, c.parameters)
                    : null}
                </div>
              </React.Fragment>
            )
          })
        } else {
          return (
            <RadioGroup
              key={'/category_' + pathName}
              defaultValue={
                keyStartsWith(categorySelections, pathName) ? '' : 'none'
              }
            >
              {children.map((c) => {
                if (
                  !c.blockchains.includes(props.blockchain) ||
                  !c.languages.includes(props.language)
                ) {
                  return null
                }
                return (
                  <React.Fragment key={c.name}>
                    <FormControlLabel
                      value={c.name}
                      // className={!c.interface?.enabled ? "coming-soon" : ""}
                      // moved down to the p tag
                      control={
                        <Radio
                          disabled={disabled || !c.interface?.enabled}
                          required={ui?.children === 'single_required'}
                          checked={categorySelections[`${pathName}/${c.name}`]}
                          onChange={(e) => {
                            setSelectedText({
                              ...selectedText,
                              [pathName]: c.title,
                            })
                            configureValues(
                              `${pathName}/${c.name}`,
                              e.target.checked,
                              true,
                            )
                          }}
                        />
                      }
                      label={
                        <div>
                          <Typography
                            variant="body1"
                            paragraph={false}
                            className={
                              !c.interface?.enabled ? 'coming-soon' : ''
                            }
                          >
                            {c.title}
                          </Typography>
                          <Typography variant="subtitle2">
                            {c.description}
                          </Typography>
                        </div>
                      }
                    />

                    <div>
                      {c.parameters != null &&
                      c.parameters.length > 0 &&
                      categorySelections[`${pathName}/${c.name}`]
                        ? showParameters(`${pathName}/${c.name}`, c.parameters)
                        : null}
                    </div>
                  </React.Fragment>
                )
              })}
              {ui?.children !== 'single_required' ? (
                <FormControlLabel
                  value={'none'}
                  control={
                    <Radio
                      onChange={() => {
                        setSelectedText({
                          ...selectedText,
                          [pathName]: false,
                        })
                        configureValues(`${pathName}/`, false, true)
                      }}
                    />
                  }
                  label={
                    <Typography variant="body1" paragraph={false}>
                      None
                    </Typography>
                  }
                />
              ) : null}
            </RadioGroup>
          )
        }
      }
    }
    return null
  }

  return (
    <>
      {props.categories.map((f) => {
        const hasValidChildren = f.children?.filter(
          (x) =>
            x.blockchains.includes(props.blockchain) &&
            x.languages.includes(props.language),
        )
        if (!hasValidChildren || hasValidChildren?.length === 0) {
          return null
        }

        return (
          <BoxWrapper
            key={f.name}
            title={f.title}
            subtitle={f.description}
            active={isActive(`/category/${f.name}`)}
            selectionText={() => {
              if (f.interface?.enabled === false) {
                return 'COMING SOON'
              }
              if (f.interface?.children === 'multiple') {
                const count = Object.keys(categorySelections).filter(
                  (x: string) =>
                    x.indexOf(`/category/${f.name}`) > -1 &&
                    categorySelections[x] === true,
                ).length
                if (count > 0) {
                  return `${count} Selected`
                }
              }

              return selectedText[`/category/${f.name}`]
            }}
          >
            <FormGroup
              className={classes.alabel}
              style={{ paddingTop: '28px', paddingBottom: 0 }}
            >
              {renderChildren(`/category/${f.name}`, f.interface, f.children)}
            </FormGroup>
          </BoxWrapper>
        )
      })}
    </>
  )
}
