import App from './components/App'
import store from './features'
import history from './utils/history'
import { grey } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import DappDone from './components/DappDone'
import { CssBaseline } from '@material-ui/core'
import ScrollToTop from './utils/scrollToTop'
// Minor change. Small Change

const defaultTheme = createMuiTheme()
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00C9FB',
    },
    secondary: {
      main: '#79CC81',
    },
  },
  typography: {
    fontFamily: "'Cabin', sans-serif",
  },
  overrides: {
    MuiRadio: {
      root: {
        padding: 10,
        color: grey[600],
      },
    },
    MuiCheckbox: {
      root: {
        padding: 10,
        color: grey[600],
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: 'none',
        borderBottom: '1px solid #e6e6e6',
      },
    },
    MuiFormGroup: {
      root: {
        padding: '10px 0',
        fontSize: 18,
      },
    },
    MuiInput: {
      root: {
        fontSize: 18,
        minWidth: '280px',
        [defaultTheme.breakpoints.down('xs')]: {
          minWidth: '100%',
        },
      },
    },
    MuiTypography: {
      h1: {
        color: '#3F3F3F',
        fontFamily: "'Cabin', sans-serif",
        fontSize: 70,
        fontWeight: 900,
        textAlign: 'left',
        letterSpacing: 'normal',
        lineHeight: '1.4',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 48,
        },
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: 28,
          textAlign: 'center',
        },
      },
      h2: {
        fontFamily: "'Cabin', sans-serif",
        fontSize: 23,
        fontWeight: 600,
        color: '#3F3F3F',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 19,
        },
      },
      h4: {
        fontFamily: "'Cabin', sans-serif",
        fontSize: 18,
        fontWeight: 600,
        color: '#3F3F3F',
      },
      h6: {
        fontFamily: "'Cabin', sans-serif",
        fontSize: 18,
        fontWeight: 600,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      },
      subtitle1: {
        fontFamily: "'Cabin', sans-serif",
        fontSize: 16,
        fontWeight: 400,
        color: grey[600],
        lineHeight: '1.4',
        paddingTop: '6px',
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
      subtitle2: {
        fontFamily: "'Cabin', sans-serif",
        fontWeight: 400,
        color: grey[500],
        lineHeight: '1.5em',
      },
      caption: {
        fontSize: 10,
        marginLeft: 6,
      },
      body1: {
        fontFamily: "'Cabin', sans-serif",
        fontSize: 18,
        color: grey[600],
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      },
      h3: {
        fontFamily: "'Cabin', sans-serif",
        fontSize: 16,
        color: grey[600],
        textTransform: 'uppercase',
        textAlign: 'left',
        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
    },
    MuiInputBase: {
      //fontFamily:"'Cabin',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
    },
    MuiButton: {
      root: {
        padding: '8px 30px',
        color: 'white',
        fontFamily: "'Cabin', sans-serif",
        fontWeight: 'bold',
        fontSize: 16,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'white',
        },
      },
      containedPrimary: {
        color: 'white',
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      },
      containedSecondary: {
        backgroundColor: 'white',
        color: '#333',
        border: '4px solid #79CC81',
        '&:hover': {
          backgroundColor: 'white',
          border: '4px solid #79CC81',
          boxShadow:
            'rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px',
        },
      },
      outlinedSecondary: {
        backgroundColor: 'white',
        color: '#333',
        border: '4px solid #e6e6e6',
        '&.Mui-disabled': {
          border: '4px solid #f6f6f6',
          backgroundColor: '#fafafa',
        },
        '&:hover': {
          border: '4px solid #d6d6d6',
          backgroundColor: 'white',
        },
      },
      contained: {
        boxShadow: 'none',
      },
      outlined: {
        padding: '8px 30px',
        border: '4px solid #e6e6e6',
        '&:hover': {
          border: '4px solid #d6d6d6',
          backgroundColor: 'white',
          boxShadow:
            'rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px',
        },
        //boxShadow:
        //   "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
      },
    },
    MuiToolbar: {
      root: {
        background: 'transparent',
      },
    },
    MuiAppBar: {
      root: {
        background: 'transparent !important',
        borderBottom: 'none',
        padding: '12px 48px',
        [defaultTheme.breakpoints.down('sm')]: {
          padding: '12px 0',
        },
      },
    },
  },
})

ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route path="/done/:projectName?">
            <DappDone />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)
