import { getManifest, ManifestState } from '../actions/manifest'
import { RootState } from '../features'
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  Icon,
  CircularProgress,
  TextField
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Error from '@material-ui/icons/Error'
import SvgIcon from '@material-ui/core/SvgIcon'
import { Action } from '@reduxjs/toolkit'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import DappFeatures from './shared/DappFeatures'
import BoxWrapper from './shared/BoxWrapper'
import LargeButton from './shared/LargeButton'
import { useHistory } from 'react-router'
import useStyles from './styles/DappBuilder.style'
import { generateDapp } from '../actions/dapp'

interface selections {
  blockchain: string
  language: string
  framework: string
  categories?: any
  name: string
}

// const loadingMessages = [
//   'Priming thermoquarks',
//   'Streamlining blocks',
//   'Optimizing code vectors',
//   'Squelching network receptors',
//   'Tuning gigasnorks',
//   'Questioning sanity',
//   'Perusing merkle mitigations',
//   'Repurposing cryptographic hashes',
//   'Oxidizing query sensors',
// ]

//var timeout: NodeJS.Timeout | null = null

export default function DappBuilder() {
  const dappCreationFrame = useRef(null)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [isError] = useState<boolean>(false)
  const {
    handleSubmit,
    register,
    errors,
    triggerValidation,
    control,
    formState,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const history = useHistory()
  const selectionDefaults: selections = {
    blockchain: 'vulcan',
    language: 'solidity',
    framework: 'vanilla',
    name: '',
    categories: {},
  }

  const [selections, setSelections] = useState<selections>({
    ...selectionDefaults,
  })
  const classes = useStyles()
  const manifest = useSelector<RootState, ManifestState>(
    (state) => state.manifest,
  )

  // const [loadingMessage, setLoadingMessage] = useState<string>(
  //   loadingMessages[0] + '...',
  // )

  const dispatch = useDispatch<ThunkDispatch<RootState, null, Action>>()
  const categoryUpdate = useCallback((categories: any) => {
    setSelections((s) => {
      return {
        ...s,
        categories,
      }
    })
  }, [])

  const loadConfiguration = () => {
    const config = localStorage.getItem('dappstarter-selections')
    if (config != null) {
      setTimeout(() => {
        // @ts-ignore
        dappCreationFrame?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 1000)
      setSelections(JSON.parse(config))
      localStorage.removeItem('dappstarter-selections')
    }
  }

  useEffect(() => {
    dispatch(getManifest())
    loadConfiguration()
  }, [dispatch])

  const onSubmit = () => {
    createDapp()
  }

  // const randomMessage = () => {
  //   setLoadingMessage(
  //     loadingMessages[Math.floor(Math.random() * loadingMessages.length)] +
  //       '...',
  //   )
  //   if (!isCreating) {
  //     if (timeout != null) {
  //       clearTimeout(timeout)
  //     }
  //     timeout = setTimeout(() => {
  //       randomMessage()
  //     }, 3000)
  //   }
  // }

  const createDapp = async () => {
    setIsCreating(true)
    dispatch(
      generateDapp({
        name: selections.name,
        blocks: {
          [`/blockchain/${selections.blockchain.toLowerCase()}`]: true,
          [`/blockchain/${selections.blockchain.toLowerCase()}/${selections.language.toLowerCase()}`]: true,
          [`/framework/${selections.framework.toLowerCase()}`]: true,
          ...selections.categories,
        },
      }),
    )
      .then((dapp) => {
        setIsCreating(false)
        history.push('/done/' + dapp.name)
      })
      .catch(() => {
        setIsCreating(false)
      })
  }


  return (
    <div style={{}}>
      <div className="block-wrap">
        <div className="blocks">
          <img src="/blocks-svg.svg" height="auto" width="1669" alt="" />
          <object
            type="image/svg+xml"
            height="auto"
            width="1064"
            data="/dapp-blocks-once.svg"
            className="checkmark-squared"
          >
            <img
              src="/dapp-blocks-once.svg"
              height="auto"
              width="1064"
              alt=""
            />
          </object>
        </div>
      </div>
      <Container className={classes.maxWidth} style={{ position: 'relative' }}>
        <div className={classes.heroWrap}>
          <Grid style={{ width: '100%' }}>
            <Box className={classes.subHeader}>
              <div className={classes.subHeaderLeft}>
                <span className={classes.heroText}>
                  Full-stack blockchain app mojo
                </span>
                <Typography variant="h1" className={classes.h1}>
                  DappStarter
                </Typography>
              </div>
              <div className="sub-header-right">
                <Typography
                  className={classes.heroParagraphText}
                  variant="body1"
                  style={{
                    lineHeight: '1.8',
                    color: 'white',
                    fontWeight: 'bold',
                    margin: '0',
                    padding: '0',
                  }}
                >
                  Accelerate your blockchain
                  <br /> development journey with customized, <br />
                  full-stack source code.
                </Typography>
              </div>
            </Box>
          </Grid>
        </div>
      </Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className={classes.maxWidth} style={{}}>
          <div className={classes.sectionPadding}>
            <div className={classes.sectionOne}>
              <div
                style={{
                  marginBottom: '72px',
                  position: 'relative',
                  display: 'flex',
                }}
              >
                <div className={classes.blockOne}>
                  <img
                    src="/block-1.svg"
                    height="120.11"
                    width="106.77"
                    alt=""
                  ></img>
                </div>
                <div className={classes.stepWrap}>
                  <span className={classes.stepNum}>Step 1</span>
                  <h2 className={classes.h2}>Smart Contract Stack</h2>
                  <p className={classes.paragraphs}>
                    DappStarter is a full stack development environment for
                    blockchains. It supports your choice of blockchain and
                    blockchain language as well as an integrated front end user
                    experience in your choice of client side framework.
                  </p>
                  <BoxWrapper
                    title={manifest.manifest.blockchain.title}
                    subtitle={manifest.manifest.blockchain.description}
                    //selectionText={selections.blockchain}
                    active={selections.blockchain.length > 0}
                    selectionText={() => {
                      if (selections.blockchain.length > 0) {
                        return `${selections.blockchain}`
                      }
                      return 'Not Configured'
                    }}
                  >
                    <FormGroup
                      className={classes.alabel}
                      style={{ paddingTop: '28px', paddingBottom: 0 }}
                    >
                      <RadioGroup>
                        <Grid container spacing={2}>
                          {manifest?.manifest?.blockchain?.children?.map(
                            (c) => {
                              return (
                                <Grid
                                  item
                                  className={classes.buttonWrap}
                                  key={c.name}
                                  hidden={c.interface?.hidden}
                                >
                                  <LargeButton
                                    selected={selections.blockchain === c.name}
                                    title={c.title}
                                    disabled={!c.interface?.enabled}
                                    hidden={c.interface?.hidden}
                                    imageUrl={c.imageUrl as string}
                                    onClick={() => {
                                      // appInsights.trackEvent({
                                      //   name: "blockchain_click",
                                      //   properties: { blockchain: c.name }
                                      // });
                                      setSelections({
                                        ...selectionDefaults,
                                        blockchain: c.name,
                                        language:
                                          manifest.manifest.language.children?.find(
                                            (x) =>
                                              x.blockchains?.includes(c.name),
                                          )?.name || '',
                                      })
                                    }}
                                  />
                                </Grid>
                              )
                            },
                          )}
                        </Grid>
                      </RadioGroup>
                    </FormGroup>
                  </BoxWrapper>
                  <BoxWrapper
                    title={manifest.manifest.language.title}
                    subtitle={manifest.manifest.language.description}
                    active={selections.language != null}
                    selectionText={() => {
                      if (selections.language != null) {
                        return selections.language
                      }
                      return 'Not Configured'
                    }}
                  >
                    <RadioGroup>
                      {manifest?.manifest?.language?.children?.map((x) => {
                        if (
                          x.blockchains != null &&
                          x.blockchains.indexOf(selections.blockchain) === -1
                        ) {
                          return null
                        }
                        return (
                          <FormControlLabel
                            disabled={!x.interface?.enabled}
                            className={
                              !x.interface?.enabled ? 'coming-soon' : ''
                            }
                            hidden={x.interface?.hidden}
                            defaultValue={selections.language}
                            key={x.name}
                            onClick={() => {
                              setSelections({
                                ...selections,
                                language: x.name,
                              })
                            }}
                            control={<Radio key={x.name} />}
                            label={x.title}
                          />
                        )
                      })}
                    </RadioGroup>
                  </BoxWrapper>
                  <BoxWrapper
                    title={manifest?.manifest?.framework?.title}
                    subtitle={manifest?.manifest?.framework?.description}
                    active={selections.framework.length > 0}
                    selectionText={() => {
                      if (selections.framework.length > 0) {
                        return `${selections.framework}`
                      }
                      return 'Not Configured'
                    }}
                  >
                    <FormGroup
                      className={classes.alabel}
                      style={{ paddingTop: '28px' }}
                    >
                      <RadioGroup>
                        <Grid container spacing={2}>
                          {manifest?.manifest?.framework?.children?.map((c) => {
                            return (
                              <Grid
                                key={c.name}
                                item
                                className={classes.buttonWrap}
                              >
                                <LargeButton
                                  selected={selections.framework === c.name}
                                  hidden={c.interface?.hidden}
                                  disabled={!c.interface?.enabled}
                                  onClick={() => {
                                    setSelections({
                                      ...selections,
                                      framework: c.name,
                                    })
                                  }}
                                  title={c.title}
                                  imageUrl={c.imageUrl as string}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </RadioGroup>
                    </FormGroup>
                  </BoxWrapper>
                </div>
              </div>
            </div>
            <div className={classes.sectionTwo}>
              <div
                style={{
                  marginBottom: '72px',
                  position: 'relative',
                  display: 'flex',
                }}
              >
                <div className={classes.blockTwo}>
                  <img
                    src="/block-2.svg"
                    height="120.11"
                    width="106.77"
                    alt=""
                  ></img>
                </div>
                <div className={classes.stepWrap}>
                  <span
                    className={classes.stepNum}
                    style={{ color: '#43DDB4' }}
                  >
                    Step 2
                  </span>
                  <h2 className={classes.h2}>Smart Contract Features</h2>
                  <p className={classes.paragraphs}>
                    The features you choose will give you the customized smart
                    contract to get just what you need for your application.
                  </p>

                  {manifest != null ? (
                    <DappFeatures
                      triggerValidation={triggerValidation}
                      blockchain={selections.blockchain}
                      language={selections.language}
                      formState={formState}
                      control={control}
                      errors={errors}
                      register={register}
                      setCategorySelections={categoryUpdate}
                      categorySelections={selections.categories}
                      categories={manifest.manifest.categories}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className={classes.sectionThree} style={{display:"none"}}>
              <div className={classes.blockThree}>
                <img
                  src="/block-3.svg"
                  height="120.11"
                  width="106.77"
                  alt=""
                ></img>
              </div>
              <div className={classes.stepWrap}>
                <span className={classes.stepNum} style={{ color: '#58E886' }}>
                  Step 3
                </span>
                <h2 className={classes.h2}>Dapp Customization</h2>
                <p className={classes.paragraphs}>
                  Tailor your blockchain app to fit your brand
                </p>

                <BoxWrapper
                  title="Dapp Name"
                  active={selections.name !== ''}
                  selectionText={selections.name !== '' ? '✔' : ''}
                  subtitle={
                    'Enter a title for your dapp.'
                  }
                >
                  <TextField
                    label={'Enter dapp name'}
                    name="dappName"
                    className={classes.textBoxField}
                    error={errors.dappName != null}
                    value="My Dapp"
                    required={true}
                    helperText={errors.dappName && 'Dapp name is required'}
                    inputRef={register({
                      required: true,
                    })}
                    onChange={(e) => {
                      // if (errors.dappName != null) {
                      //   triggerValidation();
                      // }
                      setSelections({
                        ...selections,
                        name: e.target.value,
                      })
                    }}
                  />
                </BoxWrapper>
              </div>
            </div>
            <div className={classes.bottomRow}>
              {/* {UserInfoElement()} */}
              {isError ? (
                <div
                  style={{
                    borderRadius: 4,
                    backgroundColor: 'rgb(253, 236, 234)',
                    color: 'red',
                    padding: 4,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon component={Error} color="error" /> Unable to generate
                  dapp. Please update your configuration and try again.
                </div>
              ) : null}
              <div
                style={{ textAlign: 'center', marginBottom: 6 }}
                hidden={!isCreating}
              >
                {/*loadingMessage*/}
              </div>
              <div style={{ textAlign: 'center' }} ref={dappCreationFrame}>
                <>
                  <Button
                    id="createDapp"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={() => triggerValidation()}
                    style={{ textAlign: 'right' }}
                  >
                    {isCreating ? (
                      <CircularProgress
                        variant="indeterminate"
                        size={20}
                        style={{ marginRight: '8px', color: '#fff' }}
                      ></CircularProgress>
                    ) : (
                      <SvgIcon style={{ marginRight: '8px', opacity: '.75' }}>
                        <svg
                          x="0px"
                          y="0px"
                          width="21.3px"
                          height="23.8px"
                          viewBox="0 0 21.3 23.8"
                        >
                          <defs></defs>
                          <path
                            d="M20.8,5.6l-9.6-5.5C11,0,10.7,0,10.4,0.1L0.5,5.6c-0.1,0-0.1,0.1-0.1,0.1L0.2,5.8C0.1,6,0,6.2,0,6.4v11.1
                    c0,0.3,0.2,0.6,0.4,0.8l9.6,5.5c0.1,0.1,0.3,0.1,0.4,0.1h0l0.1,0c0.1,0,0.2-0.1,0.3-0.1l9.9-5.5c0.3-0.2,0.4-0.4,0.4-0.8v-11
                    C21.3,6.1,21.1,5.8,20.8,5.6z M1.7,17v-9l7.8,4.5v9L1.7,17z M18.6,6.4l-8.2,4.5L2.6,6.4l8.2-4.5L18.6,6.4z M11.3,21.5v-9.1l8.2-4.6
                    v9.1L11.3,21.5z"
                          />
                        </svg>
                      </SvgIcon>
                    )}
                    Create Dapp
                  </Button>
                </>
              </div>
            </div>
          </div>
        </Container>
      </form>
    </div>
  )
}
