import React, { useEffect } from 'react'
import './App.module.css'
import DappBuilder from './DappBuilder'
import Header from './shared/Header'
import Footer from './shared/Footer'

export default () => {
  useEffect(() => {
    const handler = function (e: StorageEvent) {}

    window.addEventListener('storage', handler)

    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [])

  return (
    <React.Fragment>
      <Header />
      <DappBuilder />
      <Footer />
    </React.Fragment>
  )
}
