import { Button, makeStyles } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import React, { SyntheticEvent } from "react";
import CheckIcon from "@material-ui/icons/CheckCircle";

interface props {
  title: string;
  imageUrl: string;
  selected: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void;
}

const defaultTheme = createMuiTheme();
const useStyles = makeStyles({
  root: {
    textAlign: "center",
    padding: "0",
    "& .MuiButton-label": {
      display: "block",
      padding: "16px 0",
      position: "relative",
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      top: "4px",
      left: "4px",
      color: "#79CC81",
    },
    "& .MuiButton-label > .MuiButton-startIcon": {
      display: "block",
      marginRight: "0",
      marginLeft: "0",
    },
    "& .MuiButton-label > span:last-child": {
      display: "block",
      marginRight: "0",
      marginLeft: "0",
      width: "100%",
      [defaultTheme.breakpoints.down(540)]: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    "& .MuiButton-label > .MuiButton-startIcon > img": {
      display: "block",
      height: "36px",
      marginRight: "auto",
      marginLeft: "auto",
      marginBottom: "4px",
      width: "auto",
    },
  },
});

export default function LargeButton(props: props) {
  const classes = useStyles(props);
  return (
    <Button
      fullWidth={true}
      disabled={props.disabled}
      variant={props.selected ? "contained" : "outlined"}
      color={"secondary"}
      hidden={props.hidden}
      className={`${props.disabled ? "coming-soon" : ""} ${classes.root}`}
      onClick={props.onClick}
      startIcon={<img alt="" src={props.imageUrl} height={60} />}
    >
      {props.selected ? <CheckIcon /> : ""}
      <span>{props.title}</span>
    </Button>
  );
}
