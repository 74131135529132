import React from 'react'
import { makeStyles } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'

const defaultTheme = createMuiTheme()
const useStyles = makeStyles(() => ({
  logo: {
    width: '133px',
    [defaultTheme.breakpoints.down('sm')]: {
      width: '100px',
    },
  },
  sectionMobile: {
    display: 'flex',
    alignContent: 'flex-end',
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1376px',
    margin: '0 auto',
    padding: '48px 48px 96px',
    alignItems: 'center',
    fontSize: '14px',
    color: '#555',
    '& p:last-child': {
      textAlign: 'right',
    },
    [defaultTheme.breakpoints.down(860)]: {
      display: 'block',
      textAlign: 'center',
      padding: '0px 24px 60px',
      '& p:last-child': {
        textAlign: 'center',
        fontSize: '13px',
      },
    },
  },
  mainNav: {
    fontSize: '14px',
    [defaultTheme.breakpoints.down(1180)]: {
      fontSize: '13px',
    },
    [defaultTheme.breakpoints.down(860)]: {
      fontSize: '12px',
      width: '100%',
    },
    '& > ul': {
      display: 'flex',
      [defaultTheme.breakpoints.down(860)]: {
        justifyContent: 'center',
        paddingTop: '24px',
        paddingBottom: '24px',
        fontSize: '12px',
      },
      [defaultTheme.breakpoints.down(540)]: {
        display: 'block',
      },
    },
    '& li': {
      marginRight: '24px',
      [defaultTheme.breakpoints.down(540)]: {
        margin: '0 auto 6px',
      },
      '& a': {
        textTransform: 'uppercase',
        textDecoration: 'none',
        letterSpacing: '.075em',
        color: '#40DCBA',
        fontWeight: 'bold',
        transition: '.1s ease-in-out',
        '&:hover': {
          color: '#0A1F44',
          textDecoration: 'none',
        },
      },
      '&:first-child': {
        '& a': {
          color: '#2DD1E5',
        },
      },
      '&:last-child': {
        marginRight: '0',
        '& a': {
          color: '#5AE980',
          borderBottom: '3px solid rgba(0,0,0,.1)',
        },
      },
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footerRow}>
      <div style={{}}>
        <a href="https://www.decentology.com/">
          <img
            className={classes.logo}
            src="https://info.decentology.com/assets/brand/SVG/decentology-logo.svg"
            alt="TryCrypto Dapp Starter"
            height="auto"
            width="133"
          />
        </a>
      </div>
      <div>
        <nav className={classes.mainNav}>
          <ul>
            {/*
            <li>
              <Link
                href="https://www.decentology.com/dappstarter-faq"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </Link>
            </li>
  
            <li>
              <Link
                href="https://www.decentology.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                href="https://github.com/decentology/dappstarter-cli"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download CLI
              </Link>
  </li>*/}
          </ul>
        </nav>
      </div>
      <div>
        <p>
          &copy; Decentology 2023
          <br />
          Mountain View, CA
        </p>
      </div>
    </footer>
  )
}
